import React, { useContext } from "react";
import { WalletContext } from "../App";

const Pet = () => {
  //Cat
  const { index } = useContext(WalletContext);

  return (
    <div style={{ zIndex: index }} className="Pet">
      <img className="gato" src="../Imagenes/gatoCut.png" />
    </div>
  );
};

export default Pet;
