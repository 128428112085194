import Header from "./header";
import Pet from "./pet";
import Screen from "./screen";
import {
	createContext,
	useState,
	useEffect,
	useCallback,
	useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ethers } from "ethers";
import WithdrawMsg from "./withdrawmsg";
import Addresses from "../addr/addresses.json";
import { WalletContext } from "../App";

const arbChainId = "0xa4b1";

const Contents = () => {
	/*Metamask API changes handler
  - Installed
  - Network
  - Account changes
  - Chain changed
  - Connection state changed
  */

	const [index, setIndex] = useState(0);

	const {
		connected,
		setConnected,
		arbitrum,
		setArbitrum,
		installed,
		setInstalled,
		deposit,
		setDeposit,
		loading,
		setLoading,
		account,
		setAccount,
		textButton,
		setTextButton,
		multiButtonText,
		setMultiButtonText,
		provider,
		setProvider,
		updateInterval,
		setUpdateInterval,
		withdrawBool,
		setWithdrawBool,
		blockingDepositTimestamp,
		setBlockingDepositTimestamp,
	} = useContext(WalletContext);

	const navigate = useNavigate();

	window.addEventListener("load", function () {
		if (!(window.ethereum && window.ethereum.isMetaMask)) {
			setInstalled(false);
			setTextButton("MetaMask not installed");
			setMultiButtonText("MetaMask not installed");
		} else {
			setInstalled(true);
			setTextButton("Connect wallet");
			setMultiButtonText("Connect wallet");
		}
		readDepositsCall();
	});

	const AccountChangedHandler = (accounts) => {
		let display;
		let newAcc = accounts[0];
		setAccount(newAcc);
		if (accounts.length > 0) {
			display =
				accounts[0].substring(0, 6) + "..." + accounts[0].substring(38);
			setTextButton(display);
			let aux = new ethers.providers.Web3Provider(
				window.ethereum
			).getSigner();
			setProvider(aux);
		} else {
			clearInterval(updateInterval);
			setUpdateInterval(null);
			setTextButton("Connect wallet");
			setMultiButtonText("Connect wallet");
			setConnected(false);
			setAccount("");
		}
	};

	useEffect(() => {
		if ((window.ethereum && window.ethereum.isMetaMask)) {
		if (!provider) {
			let aux = new ethers.providers.Web3Provider(
				window.ethereum
			).getSigner();
			setProvider(aux);
		}
	}
	}, [account]);

	useEffect(() => {
		window.ethereum?.on("accountsChanged", AccountChangedHandler);
		return () => {
			window.ethereum?.removeListener(
				"accountsChanged",
				AccountChangedHandler
			);
		};
	});

	useEffect(() => {
		window.ethereum?.on("chainChanged", ChainChangedHandler);
		return () => {
			window.ethereum?.removeListener(
				"chainChanged",
				ChainChangedHandler
			);
		};
	});

	const ChainChangedHandler = (chainId) => {
		if (connected) {
			let display;
			if (chainId === arbChainId) {
				setArbitrum(true);
				display =
					account.substring(0, 6) + "..." + account.substring(38);
				setTextButton(display);
				setMultiButtonText("Introduce amount");
			} else {
				setArbitrum(false);
				setTextButton("Change to ARB");
				setMultiButtonText("Change to ARB");
				setLoading(false);
			}
		}
	};

	const readDeposits = useCallback(async (userAddress, startTime) => {
		const url = "https://api.hyperliquid.xyz/info";
		const payload = {
			type: "userNonFundingLedgerUpdates",
			user: userAddress,
			startTime: startTime,
		};

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				return await response.json();
			}
		} catch (error) {
			console.error(`Error: ${error.message}`);
			return [];
		}
	}, []);

	const startTime = Date.now() - 24 * 60 * 60 * 1000;
	const readDepositsCall = useCallback(() =>
		readDeposits(Addresses.gwhf_eoa_addr, startTime).then((deposits) => {
			const filteredDeposits = deposits.filter(
				(entry) =>
					entry.delta.type === "vaultDeposit" &&
					entry.delta.vault ===
						"0x1e37a337ed460039d1b15bd3bc489de789768d5e"
			);
			if (filteredDeposits.length > 0) {
				setWithdrawBool(false);
				setBlockingDepositTimestamp(
					filteredDeposits[filteredDeposits.length - 1]["time"]
				);
			} else setWithdrawBool(true);
		})
	);

	const ConnectWallet = async () => {
		window.ethereum
			.request({ method: "eth_requestAccounts" })
			.then(async (accounts) => {
				setConnected(true);

				await changeToArb();

				window.ethereum
					.request({ method: "eth_chainId" })
					.then((chainId) => {
						if (chainId === arbChainId) {
							let aux = new ethers.providers.Web3Provider(
								window.ethereum
							).getSigner();
							setProvider(aux);
							console.log(aux)
							setArbitrum(true);
							AccountChangedHandler(accounts);
							setMultiButtonText("Introduce amount");
						} else {
							setArbitrum(false);
							setLoading(false);
						}
					});
			})
			.catch((error) => {
				// console.log(error.message);
			});
	};

	const addARBNetwork = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_addEthereumChain",
				params: [
					{
						chainId: arbChainId,
						rpcUrls: ["https://arb1.arbitrum.io/rpc"],
						chainName: "Arbitrum",
						nativeCurrency: {
							name: "Ether",
							symbol: "ETH",
							decimals: 18,
						},
						blockExplorerUrls: ["https://arbiscan.io"],
					},
				],
			});
		} catch (error) {
			// console.log(error);
		}
	};

	const changeToArb = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: "0xa4b1" }],
			});
		} catch (switchError) {
			if (switchError.code === 4902) {
				await addARBNetwork();
			} else {
				// console.log("Network didn't change");
				setMultiButtonText("Change to ARB");
				setTextButton("Change to ARB");
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (!(window.ethereum && window.ethereum.isMetaMask)) {
			setInstalled(false);
			setTextButton("MetaMask not installed");
			setMultiButtonText("MetaMask not installed");
		}
		readDepositsCall();
	}, [useLocation().pathname]);

	return (
		<div className="Contents">
			<Header connect={ConnectWallet} arb={changeToArb} />
			<div className="divInvest">
				<div className="investButton">
					<span
						style={{ color: "#F0E400" }}
						className="investDashboard investText"
					>
						Invest
					</span>
					<svg
						className="selectVector"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="215 244 70 10.5"
						width="70px"
						height="10.5px"
					>
						<rect
							width="70"
							height="4.584"
							rx="1"
							fill="#F0E400"
							x="215"
							y="244"
							transform="matrix(1, 0, 0, 1, 0, 2.842170943040401e-14)"
						/>
						<path
							d="M 250.001 254.5 L 236.657 245.5 L 263.343 245.5 L 250.001 254.5 Z"
							fill="#F0E400"
							transform="matrix(1, 0, 0, 1, 0, 2.842170943040401e-14)"
						/>
					</svg>
				</div>
				<div className="dashboardButton">
					<span
						className="investDashboard dashboardTextContents"
						onClick={() => {
							navigate("/dashboard");
						}}
					>
						Dashboard
					</span>
				</div>
			</div>
			<Screen connect={ConnectWallet} arb={changeToArb} />
			<WithdrawMsg
				withdrawEnabled={withdrawBool}
				withdrawTimestamp={blockingDepositTimestamp}
			/>
			<Pet />
		</div>
	);
};

export default Contents;
