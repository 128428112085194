import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import Header from "./header";
import Pet from "./pet";
import { ethers } from "ethers";
import { WalletContext } from "../App";

const arbChainId = "0xa4b1";

const DashboardChart = () => {
	const {
		connected,
		setConnected,
		arbitrum,
		setArbitrum,
		installed,
		setInstalled,
		deposit,
		setDeposit,
		loading,
		setLoading,
		account,
		setAccount,
		textButton,
		setTextButton,
		multiButtonText,
		setMultiButtonText,
		provider,
		setProvider,
		updateInterval,
		setUpdateInterval,
		withdrawBool,
		setWithdrawBool,
		blockingDepositTimestamp,
		setBlockingDepositTimestamp,
		priceRef,
	} = useContext(WalletContext);

	const navigate = useNavigate();

	const urlCSV = "dashboard_data/growi_twr.csv";
	const urlMetrics = "dashboard_data/metrics.json";

	const [metrics, setMetrics] = useState({
		apy: 0,
		tvl: 0,
		pnl: 0,
		twr: 0,
	});
	const [chartData, setChartData] = useState(null);

	const chartRef = useRef(null);
	const chartInstance = useRef(null);

	useEffect(() => {
		const fetchMetricsData = async () => {
			const response = await fetch(urlMetrics);
			if (!response.ok)
				throw new Error(`HTTP error! Status: ${response.status}`);
			const data = await response.json();
			try {
				setMetrics({
					apy: Math.round(data.APY).toLocaleString(),
					tvl: data.TVL.toLocaleString(),
					pnl: data.PNL.toLocaleString(),
					twr: data.TWR.toLocaleString(),
				});
			} catch (error) {
				console.error("Error loading JSON:", error);
			}
		};

		const fetchCSVData = async () => {
			try {
				const response = await fetch(urlCSV);
				if (!response.ok)
					throw new Error(`HTTP error! Status: ${response.status}`);

				const data = await response.text();
				const rows = data
					.split("\n")
					.filter((row) => row.trim() !== "")
					.slice(1);

				const dates = [];
				const prices = [];

				rows.forEach((row) => {
					const [date, price] = row.split(";");
					if (date && price) {
						const [day, month, year] = date
							.trim()
							.split(" ")[0]
							.split("/");
						dates.push(`${day}/${month}/${year}`);
						prices.push(parseFloat(price.trim()));
					}
				});

				setChartData({ dates, prices });
			} catch (error) {
				console.error("Error loading CSV:", error);
			}
		};

		fetchCSVData();
		fetchMetricsData();
	}, []);

	useEffect(() => {
		if (!chartData) return;

		const createChart = () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}

			const ctx = chartRef.current.getContext("2d");
			chartInstance.current = new Chart(ctx, {
				type: "line",
				data: {
					labels: chartData.dates,
					datasets: [
						{
							label: "GROWI",
							data: chartData.prices,
							borderColor: "rgba(75, 192, 192, 1)",
							borderWidth: 3,
							fill: false,
							tension: 0.4,
							pointRadius: 0,
							pointBackgroundColor: "rgba(0, 0, 0, 0)",
							pointHoverRadius: 10,
							pointHoverBackgroundColor: "rgba(75, 192, 192, 1)",
						},
					],
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					interaction: {
						mode: "index",
						intersect: false,
					},
					plugins: {
						legend: { display: false },
						tooltip: {
							enabled: true,
							callbacks: {
								label: (context) =>
									`GROWI: $${Math.round(context.raw)}`,
							},
						},
					},
					scales: {
						x: {
							grid: { display: false },
							ticks: { display: false },
						},
						y: {
							beginAtZero: true,
							grid: { display: true },
						},
					},
				},
			});
		};

		createChart();
	}, [chartData]);

	window.addEventListener("load", function () {
		if (!(window.ethereum && window.ethereum.isMetaMask)) {
			setInstalled(false);
			setTextButton("MetaMask not installed");
			setMultiButtonText("MetaMask not installed");
		} else {
			setInstalled(true);
			setTextButton("Connect wallet");
			setMultiButtonText("Connect wallet");
		}
	});

	const AccountChangedHandler = (accounts) => {
		let display;
		let newAcc = accounts[0];
		setAccount(newAcc);
		if (accounts.length > 0) {
			display =
				accounts[0].substring(0, 6) + "..." + accounts[0].substring(38);
			setTextButton(display);
			let aux = new ethers.providers.Web3Provider(
				window.ethereum
			).getSigner();
			setProvider(aux);
		} else {
			clearInterval(updateInterval);
			setUpdateInterval(null);
			setTextButton("Connect wallet");
			setMultiButtonText("Connect wallet");
			setConnected(false);
			setAccount("");
		}
	};

	useEffect(() => {
		window.ethereum?.on("accountsChanged", AccountChangedHandler);
		return () => {
			window.ethereum?.removeListener(
				"accountsChanged",
				AccountChangedHandler
			);
		};
	});

	useEffect(() => {
		window.ethereum?.on("chainChanged", ChainChangedHandler);
		return () => {
			window.ethereum?.removeListener(
				"chainChanged",
				ChainChangedHandler
			);
		};
	});

	const ChainChangedHandler = (chainId) => {
		if (connected) {
			let display;
			if (chainId === arbChainId) {
				setArbitrum(true);
				display =
					account.substring(0, 6) + "..." + account.substring(38);
				setTextButton(display);
				setMultiButtonText("Introduce amount");
			} else {
				setArbitrum(false);
				setTextButton("Change to ARB");
				setMultiButtonText("Change to ARB");
				setLoading(false);
			}
		}
	};

	const ConnectWallet = async () => {
		window.ethereum
			.request({ method: "eth_requestAccounts" })
			.then(async (accounts) => {
				setConnected(true);

				await changeToArb();

				window.ethereum
					.request({ method: "eth_chainId" })
					.then((chainId) => {
						if (chainId === arbChainId) {
							let aux = new ethers.providers.Web3Provider(
								window.ethereum
							).getSigner();
							setProvider(aux);
							setArbitrum(true);
							AccountChangedHandler(accounts);
							setMultiButtonText("Introduce amount");
						} else {
							setArbitrum(false);
							setLoading(false);
						}
					});
			})
			.catch((error) => {
				// console.log(error.message);
			});
	};

	const addARBNetwork = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_addEthereumChain",
				params: [
					{
						chainId: arbChainId,
						rpcUrls: ["https://arb1.arbitrum.io/rpc"],
						chainName: "Arbitrum",
						nativeCurrency: {
							name: "Ether",
							symbol: "ETH",
							decimals: 18,
						},
						blockExplorerUrls: ["https://arbiscan.io"],
					},
				],
			});
		} catch (error) {
			// console.log(error);
		}
	};

	const changeToArb = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: "0xa4b1" }],
			});
		} catch (switchError) {
			if (switchError.code === 4902) {
				await addARBNetwork();
			} else {
				// console.log("Network didn't change");
				setMultiButtonText("Change to ARB");
				setTextButton("Change to ARB");
				setLoading(false);
			}
		}
	};

	useEffect(() => {}, [useLocation().pathname]);

	return (
		<div className="growi-dashboard">
			<Header connect={ConnectWallet} arb={changeToArb} />
			<div className="divInvest">
				<div className="investButton">
					<span
						className="investDashboard investTextDashboard"
						onClick={() => {
							navigate("/");
						}}
					>
						Invest
					</span>
				</div>
				<div className="dashboardButton">
					<span
						className="investDashboard dashboardText"
						style={{ color: "#F0E400" }}
					>
						Dashboard
					</span>
					<svg
						className="selectVector"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="215 244 70 10.5"
						width="70px"
						height="10.5px"
					>
						<rect
							width="70"
							height="4.584"
							rx="1"
							fill="#F0E400"
							x="215"
							y="244"
							transform="matrix(1, 0, 0, 1, 0, 2.842170943040401e-14)"
						/>
						<path
							d="M 250.001 254.5 L 236.657 245.5 L 263.343 245.5 L 250.001 254.5 Z"
							fill="#F0E400"
							transform="matrix(1, 0, 0, 1, 0, 2.842170943040401e-14)"
						/>
					</svg>
				</div>
			</div>
			<div className="metrics-container">
				<div className="metric-card">
					<h2>{metrics.apy}%</h2>
					<p>APY</p>
				</div>
				<div className="metric-card">
					<h2>${metrics.tvl}</h2>
					<p>TVL</p>
				</div>
				<div className="metric-card">
					<h2>${metrics.pnl}</h2>
					<p>Historical PNL</p>
				</div>
				<div className="metric-card">
					<h2>{metrics.twr}%</h2>
					<p>TWR</p>
				</div>
				<div className="metric-card">
					<h2>${priceRef.current}</h2>
					<p>Token Price</p>
				</div>
			</div>
			<div className="dataDiv">
				<div className="graficoToken">
					<canvas ref={chartRef}></canvas>
				</div>
			</div>
			<Pet />
		</div>
	);
};

export default DashboardChart;
