import HeaderBotones from "./headerbotons";

const Header = ({ connect, arb }) => {
	//Header
	return (
		<div className="Header">
			<a className="growiHfIcon" href="https://hf.growi.fi/">
				<img
					src="../../Imagenes/GrowiHF_Logo_White.png"
					width="157px"
					height="58px"
				></img>
			</a>

			<HeaderBotones connect={connect} arb={arb} />
		</div>
	);
};

export default Header;
