import "./App.css";
import React from "react";
import DashboardChart from "./Components/DashboardChart";

export default function Dashboard() {
	return (
		<div className="Dashboard">
            <DashboardChart/>
		</div>
	);
}
