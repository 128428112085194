import { useState } from "react";

const WithdrawMsg = ({ withdrawEnabled, withdrawTimestamp }) => {
	const [display, setDisplay] = useState(false);

	const formatDate = (date) => {
		const hours = String(date.getUTCHours()).padStart(2, "0");
		const minutes = String(date.getUTCMinutes()).padStart(2, "0");
		const seconds = String(date.getUTCSeconds()).padStart(2, "0");
		const day = String(date.getUTCDate()).padStart(2, "0");
		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const month = monthNames[date.getUTCMonth()];
		const year = date.getUTCFullYear();
		return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
	};

	return (
		<div style={{ display: "flex", justifyContent: "center" }}>
			<div
				className="WithdrawNotice"
				onMouseLeave={() => setDisplay(false)}
				onMouseEnter={() => setDisplay(true)}
				style={{ userSelect: "none" }}
			>
				<div className="WithdrawText">Withdraws available: </div>
				<div
					className="WithdrawBoolText"
					style={
						withdrawEnabled
							? { backgroundColor: "#008200" }
							: { backgroundColor: "#820000" }
					}
				>
					{withdrawEnabled ? "YES" : "NO"}
				</div>
				<svg
					className="InfoIcon"
					viewBox="0 0 1024 1024"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill="#ffffff"
						d="M512 64a448 448 0 110 896.064A448 448 0 01512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 01-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296-44.096 0-108.992 44.736-148.48 101.504 0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 017.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04 67.84 0 107.904-43.648 147.456-100.416z"
					/>
				</svg>
			</div>
			{display && (
				<div
					className="WithdrawStateMessage"
					onMouseLeave={() => setDisplay(false)}
				>
					{withdrawEnabled
						? "Currently withdrawals are available, it takes 5 minutes to bridge USDC from Hyperliquid to Arbitrum, and a 1USDC comission for Hyperliquid."
						: "Withdrawals are unavailable due to HyperLiquid's policy. Funds are locked for 24 hours after every deposit. Last deposit: " +
						  formatDate(new Date(withdrawTimestamp)) + " UTC"}
				</div>
			)}
		</div>
	);
};

export default WithdrawMsg;
