import React, { useState, createContext, useRef} from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import WebApp from './WebApp';
import Dashboard from './Dashboard';

export const WalletContext = createContext(null);

function App() {

	const [connected, setConnected] = useState(false);
	const [arbitrum, setArbitrum] = useState(false);
	const [installed, setInstalled] = useState(false);
	const [deposit, setDeposit] = useState(true);
	const [loading, setLoading] = useState(false);
	const [account, setAccount] = useState("");
	const [textButton, setTextButton] = useState("Connect wallet");
	const [multiButtonText, setMultiButtonText] = useState("Connect wallet");
	const [provider, setProvider] = useState();
	const [updateInterval, setUpdateInterval] = useState(null);
	const [withdrawBool, setWithdrawBool] = useState(true);
	const [blockingDepositTimestamp, setBlockingDepositTimestamp] = useState(null);
	const [location, setLocation] = useState(null);
	const priceRef = useRef(1);

    return (
		<div>
			<WalletContext.Provider
				value={{
					connected,
					setConnected,
					arbitrum,
					setArbitrum,
					installed,
					setInstalled,
					deposit,
					setDeposit,
					loading,
					setLoading,
					account,
					setAccount,
					textButton,
					setTextButton,
					multiButtonText,
					setMultiButtonText,
					provider,
					setProvider,
					updateInterval,
					setUpdateInterval,
					withdrawBool,
					setWithdrawBool,
					blockingDepositTimestamp,
					setBlockingDepositTimestamp,
					location,
					setLocation,
					priceRef
				}}
			>
			<Router>
				<Routes>
					<Route exact path="/" element={<WebApp />} />
					<Route exact path="/dashboard/" element={<Dashboard />} />
				</Routes>
			</Router>
			</WalletContext.Provider>
		</div>
	);
  
  

}

export default App;
